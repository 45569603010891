<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ grant.id ? 'Edit' : 'New' }} Grant, Contracted Research & Host Support
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back"
              @click="$router.push({name: 'researcher.grants.index', query: { tab: $route.query.tab, actAs: $route.query.actAs }})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="grant" />

            <TypeResource />

            <!-- ----- Only show if grant have type and it's from the 'Grant & Contracted Research' group ----- -->
            <GrantContractedResearch
              v-if="!loading && grant.type && grant.type.parent_type === 1"
              :researcher="loggedUser.roles.includes('super-admin') ? grant.user : null"
            />

            <AmountGrant v-if="!loading && grant.type && (grant.type.type === 'regular' || grant.type.type === 'in_kind')" />

            <PeopleFunded
              v-if="grant.type && (grant.type.type === 'regular' || grant.type.type === 'personnel')"
              @checkDates="checkNewPeopleDates($event)"
              @openEdit="openEdit($event)"
            />
            <!-- -----  ----- -->

            <!-- start monetary host support -->
            <MonetarySupport v-if="grant.type && grant.type.type === 'monetary'" />
            <!-- end monetary host support -->

            <IcreaRemarks :item="grant" />

            <ResearcherRemarks :item="grant" />

            <AddFile
              :id="6"
              :title="'Files'"
              :visible="false"
              :type="4"
              :files="grant.files_"
            />
          </div>
        </div>

        <div class="page-actions">
          <span id="disabled-wrapper">
            <button
              v-if="!loading"
              id="type-success"
              class="btn btn-success"
              :disabled="cantSave"
              @click="save"
            >
              <b-tooltip
                v-if="cantSave"
                placement="topleft"
                boundary="document"
                triggers="hover"
                :target="`disabled-wrapper`"
                title="You must save or update People Funded information"
              />
              <i data-feather="save" />
              <span class="ms-25 align-middle">Save and Exit</span>
            </button>
            <button
              v-else
              class="btn btn-outline-success"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Saving...</span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import IcreaRemarks from '@/views/back/partials/components/IcreaRemarks.vue'
import ResearcherRemarks from '@/views/back/partials/components/ResearcherRemarks.vue'
import AddFile from '../../admin/senior-call/components/AddFile.vue'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import GrantContractedResearch from './components/GrantContractedResearch.vue'
import TypeResource from './components/TypeResource.vue'
import AmountGrant from './components/AmountGrant.vue'
import PeopleFunded from './components/PeopleFunded.vue'
import MonetarySupport from './components/MonetarySupport.vue'
import { BTooltip } from 'bootstrap-vue'

export default {
  components: {
    SearchResearcher,
    GrantContractedResearch,
    AmountGrant,
    PeopleFunded,
    TypeResource,
    IcreaRemarks,
    AddFile,
    MonetarySupport,
    ResearcherRemarks,
    BTooltip,
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      datesIsWrong: false,
      cantSave: false,
    }
  },
  computed: {
    ...mapGetters({
      grant: 'grants/item',
      loggedUser: 'auth/admin',
    }),
  },
  watch: {
    'grant.begin_date': function (newDate) {
      if (this.grant.end_date && newDate) {
        const status = this.compareDates(newDate, this.grant.end_date)

        if (!status) {
          Vue.swal('Period of the grant are wrong', '', 'error')
        }
      }
    },
    'grant.end_date': function (newDate) {
      if (this.grant.begin_date && newDate) {
        const status = this.compareDates(this.grant.begin_date, newDate)

        if (!status) {
          Vue.swal('Period of the grant are wrong', '', 'error')
        }
      }
    },
  },
  async mounted() {
    if (this.id) {
      await this.$store.dispatch('grants/fetchId', this.id)
    } else {
      await this.$store.dispatch('grants/cleanType')
    }

    this.loading = false
  },
  methods: {
    checkForMissingAmounts() {
      let canSave = true

      if (this.grant.type.type === 'regular' || this.grant.type.type === 'monetary') {
        const missingAmounts = this.grant.amounts.findIndex(x => x.amount === 'NaN' || !x.amount)

        if (missingAmounts >= 0) {
          canSave = false
        }
      }

      return canSave
    },
    async save() {
      this.loading = true

      const checkDates = this.checkDates()

      if (checkDates) {
        Vue.swal(checkDates, '', 'error')
        this.loading = false
        return
      }

      if (this.checkForMissingAmounts()) {
        try {
          if ((this.grant.total_amount && this.grant.total_amount_institution && this.grant.total_amount_group) && this.grant.type.type !== 'personnel') {
            this.grant.total_amount = parseFloat(this.grant.total_amount.replaceAll(',', ''))
            this.grant.total_amount_institution = parseFloat(this.grant.total_amount_institution.replaceAll(',', ''))
            this.grant.total_amount_group = parseFloat(this.grant.total_amount_group.replaceAll(',', ''))
          }

          if (this.id) {
            await this.$store.dispatch('grants/update', { id: this.grant.id, data: this.grant })
          } else {
            await this.$store.dispatch('grants/create', this.grant)
          }
          this.errors = ''
        } catch (e) {
          console.log(e)
          this.errors = e.response.data.message
        }

        if (this.errors === '' && this.grant.id) {
          Vue.swal('Grant updated successfully!', '', 'success').then(() => {
            this.$router.push({ name: 'researcher.grants.index', query: { tab: this.$route.query.tab, actAs: this.$route.query.actAs } })
          })
        } else {
          // Vue.swal
        }
      } else {
        Vue.swal('Have you forgotten to distribute the amounts by years?', 'Please, kindly check and distribute it if necessary', 'warning')
      }

      this.loading = false
    },
    checkDates() {
      if (this.grant.type.type === 'personnel') {
        return false
      }

      const periodOfTheGrant = this.compareDates(this.grant.begin_date, this.grant.end_date)

      if (!periodOfTheGrant) {
        return 'Period of the grant are wrong'
      }

      if (this.grant.type.type === 'personal') {
        return false
      }

      if (this.grant.personnels.length) {
        const status = this.grant.personnels.find(e => !(this.compareDates(this.grant.begin_date, e.from)))
        if (status) {
          return 'Dates people funded aren\'t between with period of the grant'
        }
      }

      return false
    },
    checkNewPeopleDates(dates) {
      const status = this.compareDates(dates[0], dates[1])

      if (!status) {
        Vue.swal('Dates are wrong', '', 'error')
        return
      }

      if (this.grant.begin_date && this.grant.end_date) {
        const statusBegin = this.compareDates(this.grant.begin_date, dates[0])
        let statusEnd = true
        if (this.grant.type.type !== 'personnel' && this.grant.type.type !== 'regular') {
          statusEnd = this.compareDates(dates[1], this.grant.end_date)
        }
        if ((!statusBegin || !statusEnd)) {
          Vue.swal('Dates of the personnels aren\'t between with period of the grant', '', 'error')
        }
      }
    },
    compareDates(begin, end) {
      if (!begin || !end) {
        return false
      }

      const beginDate = new Date(this.getYear(begin), this.getMonth(begin), this.getDay(begin))
      const endDate = new Date(this.getYear(end), this.getMonth(end), this.getDay(end))
      if (beginDate.getTime() <= endDate.getTime()) {
        return true
      }
      return false
    },
    getDay(date) {
      return date.split(' ')[0]
    },
    getMonth(date) {
      const meses = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return meses.indexOf(date.split(' ')[1])
    },
    getYear(date) {
      return date.split(' ')[2]
    },
    openEdit(cantSave) {
      this.cantSave = cantSave
    },
  },
}
</script>
